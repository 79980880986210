import { combineReducers } from "redux";
import chartReducer from './chartReducer';
import HomeReducer from "./homeReducer";

const rootReducer = combineReducers({
    Chart:chartReducer,
    Home:HomeReducer
})

export default rootReducer;
