const initialState = {
    loading: false, 
    data:{
        labels: [],
        datasets: [
          {
            label: "Price vs Time Chart",
            backgroundColor: "#94a3b8",
            borderColor: "#94a3b8",
            data:[]
          },
        ]
    }
}

const chartReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        default: return state
        case 'GET_CHART':
            return {
                ...state,
                data:{
                    labels:payload.labels,
                    datasets: [
                      {
                        label: "Price vs Time Chart",
                        backgroundColor: "#94a3b8",
                        borderColor: "#94a3b8",
                        data: payload.data
                      },
                    ]
                }
            }
       
    }
}
export default chartReducer;

