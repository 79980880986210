const initialState={
    list:[]
}
const HomeReducer=(state=initialState,action)=>{
    const {type,payload}=action;
    switch(type){
        default: return state
        case 'HOME_PAGE' :
          return{
            ...state,
            list:[payload.data]
          }
    }
  }
  export default HomeReducer