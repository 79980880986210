import Front from "./components";

function App() {
  return (
    <div>
      <Front />
    </div>
  );
}

export default App;
