export const baseUrl = process.env.REACT_APP_BASE_URL;



// export const detailUrl =`${baseUrl}api/reqdetails/`;
// export const filterUrl= `${baseUrl}api/reqfilter/`

// https://barc.silverlinepos.com/api/reqget/
// https://barc.silverlinepos.com/api/reqdetails/${id}
//https://barc.silverlinepos.com/api/reqfilter/?time=${fromDate}&time2=${toDate}&company_name=${inputValue}

// export const getHome =async () =>{
//     const result = await axios.get(homeUrl)
//     return result
//     console.log('home url',homeUrl)
//     console.log(result, 'result123')
// }

// export const getDetail = async id=>{
//     console.log('id',id)
//     const result= await axios.get(`${detailUrl+id}`)
//     console.log('result of detail url', `${detailUrl+id}`)
//     return result
// }

// export const filterDetail = async ()=>{
//     const result = await axios.get()
// }


