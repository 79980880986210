import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Detail from './components/Detail';
import CombineChart from './components/Charts/combineChart';
import { Provider } from 'react-redux';
import store from './store';
import 'tw-elements';
import Front from './components';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <Router>
          <Routes>
            <Route path="/" element={<App />} /> 
            <Route path="/detail" element={<Detail />} />
            <Route path="/show-all-chart" element={<CombineChart />} />
          </Routes>
      </Router>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
